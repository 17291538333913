import React from 'react';
import Typing from 'react-typing-animation';
import cx from 'classnames';

import styles from './Title.module.css';

const typingSpeed = 25;

class Title extends React.Component {
    render() {
        return (
            <div className={cx(this.props.className)}>
                <div className={styles.upperTitle}>
                    <Typing speed={typingSpeed} hideCursor >
                        {this.props.upperTitle}
                    </Typing>
                </div>
                <div className={styles.mainTitle}>
                    <Typing speed={typingSpeed} hideCursor >
                        {this.props.mainTitle}
                    </Typing>
                </div>
            </div>
        );
    }
}

export default Title;
 