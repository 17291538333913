import React from 'react';

import UncontrolledAnimation from '../../../../components/UncontrolledAnimation';

import energyTypes from '../energyTypes';
import styles from './YearOverYearSingle.module.css';

class YearOverYearSingle extends React.Component {
    constructor(props) {
        super();

        // Show only energy types that have seen reduction
        let energyType;
        const reducedEnergyTypes = [];
        for (let energyType in props.data.savings) {
            if (props.data.savings[energyType] < 0 && this.getPrettifiedPercentage(props.data.savings[energyType]) !== 0) {
                reducedEnergyTypes.push(energyType);
            }
        }

        if (reducedEnergyTypes.length > 0) {
            const energyTypeId = reducedEnergyTypes[Math.floor(Math.random() * reducedEnergyTypes.length)];
            energyType = energyTypes.find(energyType => energyType.id === energyTypeId);
        } else {
            // TODO: This bugs out the dashboard after a few loops
            props.nextPage();
        }

        this.state = {
            energyType,
            componentOpacity: 0
        }
    }

    getPrettifiedPercentage = value => Math.round(Math.abs(value) * 100) / 100;

    mountComponent = () => {
        setTimeout(() => this.setState({ componentOpacity: 1 }), 500);
    };

    unmountComponent = () => {
        this.setState({ componentOpacity: 0 });

        setTimeout(this.props.nextPage, 500);
    }

    componentDidMount() {
        this.mountComponent();

        setTimeout(this.unmountComponent, this.props.duration * 1000);
    }

    render() {
        return this.state.energyType !== undefined ? (
            <div className={styles.YearOverYearSingle} style={{ opacity: this.state.componentOpacity }}>
                <div className={styles.upperRow}>
                    We reduced our
                    <UncontrolledAnimation loop animation={this.state.energyType.animation.file} width={150} height={175} />
                    consumption by
                </div>
                <div className={styles.middleRow} style={{ color: this.state.energyType.color }}>{ this.getPrettifiedPercentage(this.props.data.savings[this.state.energyType.id]) } %</div>
                <div className={styles.lowerRow}>
                    compared to the same period in {new Date().getFullYear() - 1}!
                </div>
            </div>
        ) : null;
    }
}
 
export default YearOverYearSingle;