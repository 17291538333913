import React, { Component } from 'react'
import Lottie from 'react-lottie'

class UncontrolledAnimation extends Component {
    render() {
        const options = {
            loop: this.props.loop,
            autoplay: true,
            animationData: this.props.animation,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return (
            <Lottie options={options}
                speed={this.props.speed}
                width={this.props.width}
                height={this.props.height}
                className={this.props.className}
                isClickToPauseDisabled
            />
        )
    }
}

export default UncontrolledAnimation;
