import React from 'react';
import cx from 'classnames';

import styles from './BottomBar.module.css';
import universityLogo from './images/UniversityOfTwente.svg';

const BottomBar = (props) => {
    return ( 
        <div className={cx(props.className, styles.BottomBar)} >
            <div className={styles.metaTextWrapper}>
                <div>We are actively reducing our energy consumption since 2005.</div>
                <div>Learn what you can do at <a href="https://utwente.nl/sustainability">https://utwente.nl/sustainability</a></div>
            </div>
            <div className={styles.universityLogoWrapper}>
                <img className={styles.universityLogo} src={universityLogo} alt="University of Twente" />
            </div>
        </div>
    );
}
 
export default BottomBar;