import React from 'react';
import Typing from 'react-typing-animation';

import UncontrolledAnimation from '../../../components/UncontrolledAnimation';

import styles from './SolarPanels.module.css';
import solarPanelAnimation from './animations/house.json';
import electricityAnimation from './animations/electricity.json';
import { ReactComponent as SunImage } from './images/sun.svg';

class SolarPanels extends React.Component {
    state = {
        currentSlide: 0,
        rightContentOpacity: 0
    }

    nextSlide = () => {
        this.setState({ rightContentOpacity: 0 });
        setTimeout(() => this.setState({ currentSlide: this.state.currentSlide + 1 }), 500);
        setTimeout(() => this.setState({ rightContentOpacity: 1 }), 550);
    }

    componentDidMount() {
        setTimeout(this.nextSlide, 750);
        setTimeout(this.nextSlide, 6500);

        setTimeout(this.props.nextPage, this.props.duration * 1000);
    }

    render() {
        return (
            <div className={styles.SolarPanels}>
                <div className={styles.sunContainer}>
                    <div className={styles.sunAnimation}>
                        <div className={styles.sunAnimationAfter}>
                            <SunImage />
                        </div>
                    </div>
                </div>
                <div className={styles.solarPanelAnimation}>
                    <UncontrolledAnimation animation={solarPanelAnimation} width={800} height={700} />
                </div>
                <div className={styles.rightContent} style={{ opacity: this.state.rightContentOpacity }}>
                    { this.state.currentSlide === 1 &&
                        <div className={styles.comparisonText}>
                            <Typing speed={10} hideCursor>
                                Solar panels have been placed on parts of the Horst and the new Technohal
                            </Typing>
                        </div>
                    }
                    { this.state.currentSlide === 2 && (
                        <div className={styles.comparison}>
                            <div className={styles.comparisonText}>
                                Today, our solar panels generated
                            </div>
                            <div className={styles.statistic}>
                                <div className={styles.statisticNumber} >
                                    { Math.round(this.props.data.solar.dayGeneration) }
                                </div>
                                <div className={styles.statisticUnit}>
                                    <UncontrolledAnimation animation={electricityAnimation} width={75} height={100} />
                                    <div className={styles.statisticUnitText} style={{ color: '#fdbe00' }}>kWh</div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default SolarPanels;