import React from 'react';
import cx from 'classnames';

import UncontrolledAnimation from '../../../../components/UncontrolledAnimation';

import energyTypes from '../energyTypes';
import styles from './YearOverYearAll.module.css';
import { ReactComponent as ArrowDownIcon } from './images/arrow-down.svg';

class YearOverYearAll extends React.Component {
    state = {
        shownEnergyTypes: 0,
        fullyMounted: false,
    }

    mountComponent = () => setTimeout(() => {
        this.setState({ shownEnergyTypes: this.state.shownEnergyTypes + 1 })
    }, 500);

    addTypeInterval = setInterval(() => {
        this.setState({ shownEnergyTypes: this.state.shownEnergyTypes + 1 });
    }, 1500);

    unmountComponent = () => {
        clearInterval(this.addTypeInterval);
        this.setState({ fullyMounted: true });

        this.setState({ shownEnergyTypes: 0 })
        setTimeout(this.props.nextPage, 500);
    }

    componentDidMount() {
        this.mountComponent();
    }

    render() {
        if (this.state.shownEnergyTypes === 5) {
            setTimeout(this.unmountComponent, 2000);
        }

        return (
            <div className={styles.EnergySavings}>
                { energyTypes.map((energyType, index) => (
                    <div className={cx(styles.energyType, styles[energyType.id], { [styles.show]: this.state.shownEnergyTypes > index })} key={energyType.id}>
                        { (this.state.shownEnergyTypes > index || this.state.fullyMounted) && <UncontrolledAnimation animation={energyType.animation.file} speed={0.5} loop width={75} height={100} /> }
                        <div className={styles.name} style={{ color: energyType.color }}>{energyType.name}</div>
                        <div className={styles.savings}>
                            <ArrowDownIcon className={cx(styles.arrow, { [styles.rotatedArrow]: this.props.data.savings[energyType.id] > 0 })} />
                            { Math.round(Math.abs(this.props.data.savings[energyType.id]) * 100 ) / 100 } %
                        </div>
                    </div>
                )) }
            </div>    
        );
    }
}
 
export default YearOverYearAll;