import electricityAnimation from './animations/electricity.json';
import heatAnimation from './animations/heat.json';
import gasAnimation from './animations/gas.json';
import coldAnimation from './animations/cold.json';
import waterAnimation from './animations/water.json';

const energyTypes = [
    {
        name: 'Electricity',
        id: 'electricity',
        color: '#fdbe00',
        animation: {
            file: electricityAnimation,
            sizeRatio: 1.25
        },
    },
    {
        name: 'Gas',
        id: 'gas',
        color: '#409d35',
        animation: {
            file: gasAnimation,
            sizeRatio: 1.25
        }
    },
    {
        name: 'Cold',
        id: 'cold',
        color: '#6477dc',
        animation: {
            file: coldAnimation,
            sizeRatio: 1.25
        }
    },
    {
        name: 'Heat',
        id: 'heat',
        color: '#ed7908',
        animation: {
            file: heatAnimation,
            sizeRatio: 1.25
        }
    },
    {
        name: 'Water',
        id: 'water',
        color: '#63b0e4',
        animation: {
            file: waterAnimation,
            sizeRatio: 1.25
        }
    }
]

export default energyTypes;