import React from 'react';
import Typing from 'react-typing-animation';

import UncontrolledAnimation from '../../../components/UncontrolledAnimation';

import styles from './StreetLights.module.css';
import streetLightAnimation from './animations/streetlight.json';
import electricityAnimation from './animations/electricity.json';
import { ReactComponent as MoonImage } from './images/moon.svg';

class StreetLights extends React.Component {
    state = { currentSlide: 0, rightContentOpacity: 0 }

    nextSlide = () => {
        this.setState({ rightContentOpacity: 0 });
        setTimeout(() => this.setState({ currentSlide: this.state.currentSlide + 1 }), 500);
        setTimeout(() => this.setState({ rightContentOpacity: 1 }), 550);
    }

    componentDidMount() {
        setTimeout(this.nextSlide, 750);
        setTimeout(this.nextSlide, 6500);

        setTimeout(this.props.nextPage, this.props.duration * 1000);
    }

    render() {
        return (
            <div className={styles.StreetLights}>
                <div className={styles.moonContainer}>
                    <div className={styles.moonAnimation}>
                        <div className={styles.moonAnimationAfter}>
                            <MoonImage />
                        </div>
                    </div>
                </div>
                <div className={styles.streetLightAnimation}>
                    <UncontrolledAnimation animation={streetLightAnimation} width={600} height={600} />
                </div>
                <div className={styles.rightContent} style={{ opacity: this.state.rightContentOpacity }}>
                    { this.state.currentSlide === 1 &&
                        <div className={styles.comparisonText}>
                            <Typing speed={10} hideCursor>
                                In the last few years, most street lights on campus have been replaced by energy-efficient LED variants
                            </Typing>
                        </div>
                    }
                    { this.state.currentSlide === 2 && (
                        <div className={styles.comparison}>
                            <div className={styles.comparisonText}>
                                The yearly savings count up to
                            </div>
                            <div className={styles.statistic}>
                                <div className={styles.statisticNumber} >
                                    11,778
                                </div>
                                <div className={styles.statisticUnit}>
                                    <UncontrolledAnimation animation={electricityAnimation} width={75} height={100} />
                                    <div className={styles.statisticUnitText} style={{ color: '#fdbe00' }}>kWh</div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default StreetLights;