import React, { Component } from 'react'
import Lottie from 'react-lottie'

class ControlledAnimation extends Component {
    state = {
        isPaused: true,
        passedIntermediate: false,
    }

    startNextSequence = () => {
        this.setState({ isPaused: false, isStopped: false });
    }

    render() {
        const options = {
            autoplay: false,
            animationData: this.props.animation,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        const eventListeners = [
            {
                eventName: 'enterFrame',
                callback: e => {
                    if (Math.round(e.currentTime) === this.props.intermediateFrame && !this.state.passedIntermediate) {
                        this.setState({ isPaused: true, passedIntermediate: true });
                    }
                    if (Math.round(e.currentTime) === this.props.finalFrame) {
                        this.setState({ isPaused: true });
                    }
                }
            }
        ]

        return (
            <Lottie
                options={options}
                isPaused={this.state.isPaused}
                speed={this.props.speed}
                width={this.props.width}
                height={this.props.height}
                className={this.props.className}
                eventListeners={eventListeners}
                isClickToPauseDisabled
            />
        )
    }
}

export default ControlledAnimation;
