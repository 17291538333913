import React from 'react';

import Title from './Title/Title';
import BottomBar from './BottomBar/BottomBar';

import pages from './pages';
import styles from './App.module.css';

class App extends React.Component {
    state = {
        currentPageIndex: null,
        data: null,
    }

    componentDidMount() {
        this.getRandomStartingPage();
        this.fetchData();

        // Periodically fetch new data
        setInterval(this.fetchData, 3600000);
    }

    fetchData = async () => {
        const result = await fetch(process.env.REACT_APP_DASHBOARD_API);
        const data = await result.json();
                
        this.setState({ data });
    }

    nextPage = () => {
        let nextPageIndex = (this.state.currentPageIndex + 1) % pages.length;

        // Prevent showing pages without live data
        while (pages[nextPageIndex].needsLiveData && !this.state.data) {
            nextPageIndex = (nextPageIndex + 1) % pages.length;
        }

        this.setState({ currentPageIndex: nextPageIndex });
    }

    getRandomStartingPage = () => {
        const indices = pages.reduce((prevValue, page, currentIndex) => prevValue.concat(page.needsLiveData ? [] : currentIndex), [])

        this.setState({ currentPageIndex: indices[Math.floor(Math.random() * indices.length)] });
    }

    render() {
        const currentPage = this.state.currentPageIndex !== null ? pages[this.state.currentPageIndex] : null;

        return currentPage !== null && (
            <div className={styles.App}>
                <Title className={styles.Title} mainTitle={currentPage.mainTitle} upperTitle={currentPage.upperTitle} key={currentPage.mainTitle} duration={currentPage.duration} />
                <div className={styles.Content}>
                    { React.createElement(currentPage.reactElement, { data: this.state.data, duration: currentPage.duration, nextPage: this.nextPage }) }
                </div>
                <BottomBar className={styles.BottomBar} />
            </div>
        );
    }
}
        
export default App;
