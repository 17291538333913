import YearOverYearAll from './Content/Statistics/YearOverYear/YearOverYearAll/YearOverYearAll';
import YearOverYearSingle from './Content/Statistics/YearOverYear/YearOverYearSingle/YearOverYearSingle';
import SolarPanels from './Content/Measures/SolarPanels/SolarPanels';
import StreetLights from './Content/Measures/StreetLights/StreetLights';
import EnergyPerStudent from './Content/Statistics/EnergyPerStudent/EnergyPerStudent';

const pages = [
    {
        mainTitle: "LED street lights on the campus",
        upperTitle: "Sustainability measures",
        reactElement: StreetLights,
        needsLiveData: false,
        duration: 14
    },
    {
        mainTitle: "Energy consumption vs students",
        upperTitle: "Sustainability data",
        reactElement: EnergyPerStudent,
        needsLiveData: false,
        duration: 16
    },
    {
        mainTitle: "Year-over-year energy usage comparison",
        upperTitle: "Sustainability statistics",
        reactElement: YearOverYearAll,
        needsLiveData: true,
        duration: 16,
    },
    {
        mainTitle: "Year-over-year energy usage comparison",
        upperTitle: "Sustainability statistics",
        reactElement: YearOverYearSingle,
        needsLiveData: true,
        duration: 12
    },
    {
        mainTitle: "Generating our own electricity",
        upperTitle: "Sustainability measures",
        reactElement: SolarPanels,
        needsLiveData: true,
        duration: 14
    }
];

export default pages;