import React from 'react';

import UncontrolledAnimation from '../../../components/UncontrolledAnimation';
import ControlledAnimation from '../../../components/ControlledAnimation';

import styles from './EnergyPerStudent.module.css';
import chartAnimation from './animations/chart.json';
import arrowAnimation from './animations/arrow.json';

class EnergyPerStudent extends React.Component {
    state = { currentSlide: 0, leftContentOpacity: 0 }

    animationRef = React.createRef();

    nextSlide = () => {
        this.setState({ leftContentOpacity: 0 });
        setTimeout(() => this.setState({ currentSlide: this.state.currentSlide + 1 }), 500);
        setTimeout(() => this.setState({ leftContentOpacity: 1 }), 550);
    }

    unmountComponent = () => {
        this.animationRef.current.startNextSequence();

        this.setState({ leftContentOpacity: 0 });

        setTimeout(this.props.nextPage, 3000);
    }

    componentDidMount() {
        setTimeout(this.unmountComponent, this.props.duration * 1000);
        setTimeout(() => {
            this.setState({ leftContentOpacity: 1 });
            this.animationRef.current.startNextSequence();
        }, 1000);

        setTimeout(this.nextSlide, 12000);
    }

    render() {
        return (
            <div className={styles.EnergyPerStudent}>
                { this.state.currentSlide === 0 &&
                    <div className={styles.legendContainer} style={{ opacity: this.state.leftContentOpacity }} >
                        <div className={styles.legendItem}>
                            <div className={styles.legendAnimation}>
                                <div className={styles.barGraphic} />
                            </div>
                            <span className={styles.legendText}>Our energy consumption is decreasing every year...</span>
                        </div>
                        <div className={styles.legendItem}>
                            <UncontrolledAnimation speed={0.25} className={styles.legendAnimation} loop animation={arrowAnimation} width={50} height={75} />
                            <span className={styles.legendText}>...while the amount of students is rising!</span>
                        </div>
                    </div>
                }
                { this.state.currentSlide === 1 &&
                    <div className={styles.energyDataContainer} style={{ opacity: this.state.leftContentOpacity }} >
                        <div className={styles.energyDataText}>
                            For more energy-related data, visit:
                        </div>
                        <div className={styles.energyDataLink}>energydata.utwente.nl</div>
                    </div>
                }
                <div className={styles.chartContainer}>
                    <ControlledAnimation
                        ref={this.animationRef}
                        speed={0.4}
                        animation={chartAnimation}
                        width={1050}
                        height={700}
                        intermediateFrame={89}
                        finalFrame={140}
                    />
                </div>
            </div>
        );
    }
}

export default EnergyPerStudent;